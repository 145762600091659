import React from 'react';
import {FeaturesSection,FeaturesLeft,FeaturesRight,FeaturesCard,
FeaturesCardImg,FeaturesCardIcon,FeaturesCardHeading,FeaturesCardPara,
FeaturesLeftH3,FeaturesLeftPara,FeaturesLeftBtn,FeaturesRow
} from './features.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';


const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Features {
                    DelayConstant
                    FeaturesLeftH3
                    FeaturesLeftPara
                    FeaturesLeftBtn
                    FeaturesRight{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="4">
                        <FeaturesLeft>
                            <Fade left delay={1*JSONData.saasJson.Features.DelayConstant}>
                            <FeaturesLeftH3>{JSONData.saasJson.Features.FeaturesLeftH3}</FeaturesLeftH3>
                            </Fade>
                            <Fade left delay={2*JSONData.saasJson.Features.DelayConstant}>
                            <FeaturesLeftPara>{JSONData.saasJson.Features.FeaturesLeftPara}</FeaturesLeftPara>
                            </Fade>
                            <Fade left delay={3*JSONData.saasJson.Features.DelayConstant}>
                            <FeaturesLeftBtn href="#">{JSONData.saasJson.Features.FeaturesLeftBtn}</FeaturesLeftBtn>
                            </Fade>
                        </FeaturesLeft>
                    </Col>
                    <Col md="12" lg="8">
                        <FeaturesRight>
                            <Row>
                            { 
                                JSONData.saasJson.Features.FeaturesRight.map((item,idx) => {
                                return <Col lg="6" md="6" sm="12">
                                        <Fade right delay={(idx+1)*JSONData.saasJson.Features.DelayConstant}>
                                        <FeaturesCard>
                                            <FeaturesCardImg>
                                                <FeaturesCardIcon src={item.FeaturesCardIcon} alt=""/>
                                            </FeaturesCardImg>
                                            <FeaturesCardHeading>{item.FeaturesCardHeading}</FeaturesCardHeading>
                                            <FeaturesCardPara>{item.FeaturesCardPara}</FeaturesCardPara>
                                        </FeaturesCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </FeaturesRight>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesSection>

    );
}

export default Features;