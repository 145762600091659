import React,{Component} from 'react';
import {BannerWrapper,
    BannerContents,
    BannerContentLeft,BannerVideoBtn,
    BannerContentRight,
    BannerBtn,
    BannerPara,BannerBtnsWrapper,
    BannerH3,
    BannerH4,PlayIconCustom
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { StaticQuery, graphql } from 'gatsby';
import LoginDialog from '../LoginDialog';
import GatsImg from "gatsby-image";

class Banner extends Component{
    constructor(props){
        super(props);
        this.state = {modal: false}
    }

    handleClose = () => this.setState({ modal: !this.state.modal });

    render(){
        return(
            <BannerWrapper id="homeContainer">
                <Container>
                    <BannerContents>
                        <BannerContentLeft>
                            <Fade delay={1*this.props.saasJson.Banner.DelayConstant}>
                                <BannerH3>{this.props.saasJson.Banner.BannerH3}</BannerH3>
                                <BannerH4>{this.props.saasJson.Banner.BannerH4}</BannerH4>
                                <BannerPara>{this.props.saasJson.Banner.BannerPara}</BannerPara>
                            </Fade>
                            <Fade delay={2*this.props.saasJson.Banner.DelayConstant}>
                                <BannerBtnsWrapper>
                                    <BannerBtn onClick={this.handleClose}>{this.props.saasJson.Banner.BannerBtn}</BannerBtn>
                                    <BannerVideoBtn href="#"><PlayIconCustom size={45}/>{this.props.saasJson.Banner.BannerVideoBtnTxt}</BannerVideoBtn>
                                </BannerBtnsWrapper>
                            </Fade>
                        </BannerContentLeft>


                        <BannerContentRight>
                            <Fade delay={3*this.props.saasJson.Banner.DelayConstant}>
                                <GatsImg 
                                    fluid={this.props.saasJson.Banner.BannerImage.childImageSharp.fluid} 
                                    alt=""
                                />
                            </Fade>
                        </BannerContentRight>
                        
                    </BannerContents>
                    <LoginDialog modalFlag = {this.state.modal} handleClose ={this.handleClose}/>
                </Container>
            </BannerWrapper>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
        query{
            saasJson {
                Banner {
                    DelayConstant
                    BannerH3
                    BannerH4
                    BannerPara
                    BannerBtn
                    BannerVideoBtnTxt
                    BannerImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
            
        `}
        render={(data) => (
            <Banner saasJson={data.saasJson}/>
        )}
    />
)