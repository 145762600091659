import React from 'react';
import {KeyFeaturesSection,KeyFeaturesHeading,KeyFeaturesInner,KeyFeaturesRightLi,
    KeyFeaturesRightLih3,KeyFeaturesRightLipara,KeyFeaturesPara,KeyFeaturesHeadingWrapper,
    KeyFeaturesRightBtn,KeyFeaturesRightUl,KeyFeaturesRight,KeyFeaturesLeft,KeyFeaturesLiImgCol,CheckIcon,
    KeyFeaturesLiContentCol} from './keyfeatures.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const KeyFeatures = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                KeyFeatures {
                    DelayConstant
                    KeyFeaturesHeading
                    KeyFeaturesPara
                    KeyFeaturesLeftImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    KeyFeaturesRightUl{
                        KeyFeaturesRightLih3
                        KeyFeaturesRightLipara
                    }
                    KeyFeaturesRightBtn
                }
            }
        }
    `);
    return (
        <KeyFeaturesSection id="KeyFeaturesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <KeyFeaturesHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.KeyFeatures.DelayConstant}>
                            <KeyFeaturesHeading>{JSONData.saasJson.KeyFeatures.KeyFeaturesHeading}</KeyFeaturesHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.KeyFeatures.DelayConstant}>
                            <KeyFeaturesPara>{JSONData.saasJson.KeyFeatures.KeyFeaturesPara}</KeyFeaturesPara>
                            </Fade>
                        </KeyFeaturesHeadingWrapper>
                    </Col>
                </Row>
                <KeyFeaturesInner>
                    <Row>
                        <Col lg="6" md="12">
                        <Fade left delay={1*JSONData.saasJson.KeyFeatures.DelayConstant}>
                            <KeyFeaturesLeft>
                                <GatsImg 
                                    fluid={JSONData.saasJson.KeyFeatures.KeyFeaturesLeftImg.childImageSharp.fluid} 
                                    className="KeyFeaturesLeftImg"
                                    imgStyle={{
                                        maxHeight:"auto",
                                        width:"100%",
                                        marginBottom:"0px",
                                    }}
                                    alt=""
                                />
                            </KeyFeaturesLeft>
                        </Fade>
                        </Col>
                        <Col lg="6" md="12">
                            <KeyFeaturesRight>
                                <KeyFeaturesRightUl>
                                { 
                                    JSONData.saasJson.KeyFeatures.KeyFeaturesRightUl.map((item,idx) => {
                                    return  <KeyFeaturesRightLi>
                                            <Fade right delay={(idx+1)*JSONData.saasJson.KeyFeatures.DelayConstant}>
                                            <KeyFeaturesLiImgCol>
                                                <CheckIcon/>
                                            </KeyFeaturesLiImgCol>
                                            <KeyFeaturesLiContentCol>
                                                <KeyFeaturesRightLih3>{item.KeyFeaturesRightLih3}</KeyFeaturesRightLih3>
                                                <KeyFeaturesRightLipara>{item.KeyFeaturesRightLipara}</KeyFeaturesRightLipara>
                                            </KeyFeaturesLiContentCol>
                                            </Fade>        
                                        </KeyFeaturesRightLi>
                                    
                                    })
                                }
                                </KeyFeaturesRightUl>
                                <Fade right delay={4*JSONData.saasJson.KeyFeatures.DelayConstant}>
                                    <KeyFeaturesRightBtn href="#">{JSONData.saasJson.KeyFeatures.KeyFeaturesRightBtn}</KeyFeaturesRightBtn>
                                </Fade>
                            </KeyFeaturesRight>
                        </Col>
                    </Row>
                </KeyFeaturesInner>
            </Container>
        </KeyFeaturesSection>
    );
}

export default KeyFeatures;