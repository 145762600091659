import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {TestimonialsSection,TestimonialsContainer,CardOuterWrapper,Card,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout,ProfileName,Designation,LeftQuote,
    Testimonial,ImgButton,PrevImg,NextImg,NextPrev,HeadingWrapper} from './testimonials.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SectionHeading,SectionSubHeading} from "../Common/common.style";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        
        return(
            <TestimonialsSection id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.saasJson.Testimonials.DelayConstant}>
                                <HeadingWrapper>
                                    <SectionHeading>{this.props.saasJson.Testimonials.TestimonialHeading}</SectionHeading>
                                    <SectionSubHeading>{this.props.saasJson.Testimonials.TestimonialSubHeading}</SectionSubHeading>
                                </HeadingWrapper>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Fade bottom delay={1*this.props.saasJson.Testimonials.DelayConstant}>
                    <TestimonialsContainer>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                                this.props.saasJson.Testimonials.CardProfileLayout.map((item,idx) => {
                                return <CardOuterWrapper>
                                <Card>
                                    <LeftQuote/>
                                    <Testimonial>
                                            {item.Testimonial}
                                    </Testimonial>
                                </Card>
                                <CardProfileLayout>
                                        <ProfilePicLayout>
                                            <GatsImg 
                                                fluid={item.ProfilePic.childImageSharp.fluid} 
                                                alt=""
                                                className="ProfilePic"
                                            />
                                        </ProfilePicLayout>
                                        <ProfileNameLayout>
                                            <ProfileName>
                                                {item.ProfileName}
                                            </ProfileName>
                                            <Designation>
                                                {item.Designation}
                                            </Designation>
                                        </ProfileNameLayout>
                                    </CardProfileLayout>
                            </CardOuterWrapper>
                                })
                            }
                        </Slider>
                    </TestimonialsContainer>
                </Fade>
                <NextPrev>
                    <ImgButton onClick={this.previous} aria-label="Prev Button">
                        <PrevImg src={this.props.saasJson.Testimonials.PrevImg} alt=""/>
                    </ImgButton>
                    <ImgButton onClick={this.next} aria-label="Next Button">
                        <NextImg src={this.props.saasJson.Testimonials.NextImg} alt=""/>
                    </ImgButton>
                </NextPrev>
            </TestimonialsSection>
        );
    }
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                    saasJson{
                        Testimonials{
                            TestimonialHeading
                            TestimonialSubHeading
                            CardProfileLayout{
                                ProfilePic{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                ProfileName
                                Designation
                                TestimonailHeading
                                Testimonial
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Testimonials saasJson={data.saasJson}/>
        )}
    />
  )