import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'DM Sans', sans-serif;
        font-weight:400;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        color:#424345;
    }
    a{
        font-family: 'DM Sans', sans-serif;
        font-weight:500;
        font-size:16px;
    }

    
`;

export default GlobalStyle;