import React from 'react';
import {PricingSection,PricingHeadingWrapper,PricingSubHeading,
    PricingHeading,PricingPlanCol,PricingPlanImg,PricingPlanHeading,PricingPlanPrice,
    PricingPlanLine,PricingPlanBtn,PricingPlanLightText,PricingPlanDarkText,PricingPlanColCenter,
    PricingPlanBtnCenter
} from './pricing.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Pricing = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Pricing {
                    DelayConstant
                    PricingHeading
                    PricingSubHeading
                    PricingPlanCol{
                        PricingPlanHeading
                        PricingPlanImg
                        PricingPlanPrice
                        PricingPlanPriceSpan
                        PricingPlanDarkText{
                            Text
                        }
                        PricingPlanLightText{
                            Text
                        }
                        PricingPlanBtn
                    }
                }
            }
        }
    `);

    return (
        <PricingSection id="pricingContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PricingHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.Pricing.DelayConstant}>
                                <PricingHeading>
                                    {JSONData.saasJson.Pricing.PricingHeading}
                                </PricingHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.Pricing.DelayConstant}>
                                <PricingSubHeading>
                                    {JSONData.saasJson.Pricing.PricingSubHeading}
                                </PricingSubHeading>
                            </Fade>
                        </PricingHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                        <Fade bottom delay={(0+1)*JSONData.saasJson.Pricing.DelayConstant}>
                            <PricingPlanCol>
                                <PricingPlanHeading>{JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanHeading}</PricingPlanHeading>
                                <PricingPlanImg src={JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanImg} alt=""/>
                                <PricingPlanPrice>{JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanPrice} / <span>{JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                <PricingPlanLine/>
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <PricingPlanBtn href="#">{JSONData.saasJson.Pricing.PricingPlanCol[0].PricingPlanBtn}</PricingPlanBtn>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(1+1)*JSONData.saasJson.Pricing.DelayConstant}>
                            <PricingPlanColCenter>
                                <PricingPlanHeading>{JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanHeading}</PricingPlanHeading>
                                <PricingPlanImg src={JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanImg} alt=""/>
                                <PricingPlanPrice>{JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanPrice} / <span>{JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                <PricingPlanLine/>
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <PricingPlanBtnCenter href="#">{JSONData.saasJson.Pricing.PricingPlanCol[1].PricingPlanBtn}</PricingPlanBtnCenter>
                            </PricingPlanColCenter>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(2+1)*JSONData.saasJson.Pricing.DelayConstant}>
                            <PricingPlanCol>
                                <PricingPlanHeading>{JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanHeading}</PricingPlanHeading>
                                <PricingPlanImg src={JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanImg} alt=""/>
                                <PricingPlanPrice>{JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanPrice} / <span>{JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                <PricingPlanLine/>
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                { 
                                    JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <PricingPlanBtn href="#">{JSONData.saasJson.Pricing.PricingPlanCol[2].PricingPlanBtn}</PricingPlanBtn>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </PricingSection>
    );
}

export default Pricing;