import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterSection,FooterCol,FooterTitle,FooterPara,
    FooterLi,FooterSocial,BottomFooterPara,BottomFooterRight,
    FooterAddWrapper,FooterAddLeft,FooterAddRight,FooterAddImg,FooterAddText,
    BottomFooterWrapper,BottomLink,FooterLogo,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon} from './footer.style';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Footer {
                    FooterLogo
                    Items
                    FooterCol2{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterAddWrapper{
                            FooterAddImg
                            FooterAddText
                        }
                    }
                    FooterCol5{
                        FooterSocial{
                            FooterSocialImg
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);

    return (
        <div>
        <FooterSection>
            <Container>
                <Fade bottom delay={1*JSONData.saasJson.Footer.DelayConstant}>
                <Row>
                    <Col md="3">
                        <FooterCol>
                            <FooterLogo src={JSONData.saasJson.Footer.FooterLogo} alt=""/>
                        </FooterCol>
                    </Col>
                    <Col md="3">
                        <FooterCol>
                            <FooterTitle>{JSONData.saasJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                            {
                                JSONData.saasJson.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                return <FooterPara>
                                    {item.Para}
                                    </FooterPara>
                                })
                            }
                        </FooterCol>
                    </Col>
                    <Col md="3">
                        <FooterCol>
                            <FooterTitle>{JSONData.saasJson.Footer.FooterCol3.FooterTitle}</FooterTitle>
                            <ScrollSpy offset={-59} items={JSONData.saasJson.Footer.Items} currentClassName="is-current">
                            {
                                JSONData.saasJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                return <FooterLi>
                                    <AnchorLink offset={55} href={item.Href}>
                                        {item.FooterMenu}
                                    </AnchorLink>
                                </FooterLi>
                                })
                            }
                            </ScrollSpy>
                        </FooterCol>
                    </Col>
                    <Col md="3">
                        <FooterCol>
                            <FooterTitle>{JSONData.saasJson.Footer.FooterCol4.FooterTitle}</FooterTitle>
                            {
                                JSONData.saasJson.Footer.FooterCol4.FooterAddWrapper.map((item,idx) => {
                                return <FooterAddWrapper>
                                        <FooterAddLeft>
                                            <FooterAddImg src={item.FooterAddImg} alt=""/>
                                        </FooterAddLeft>
                                        <FooterAddRight>
                                            <FooterAddText>{item.FooterAddText}</FooterAddText>
                                        </FooterAddRight>
                                    </FooterAddWrapper>
                                })
                            }
                        </FooterCol>
                    </Col>
                </Row>
                </Fade>
            </Container>
        </FooterSection>
        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={2*JSONData.saasJson.Footer.DelayConstant}>
                <Row>
                    <Col md="6">
                        <BottomFooterPara>
                            {JSONData.saasJson.Footer.BottomFooterPara}
                            <BottomLink rel="noreferrer" href={JSONData.saasJson.Footer.BottomFooterLink} target="_blank">
                            {JSONData.saasJson.Footer.BottomFooterName}
                            </BottomLink>
                        </BottomFooterPara>
                    </Col>
                    <Col md="6">
                        <BottomFooterRight>
                        <FooterSocial href="#" aria-label="Facebook Link">
                            <FbIcon/>
                        </FooterSocial>
                        <FooterSocial href="#" aria-label="Twitter Link">
                            <TwitterIcon/>
                        </FooterSocial>
                        <FooterSocial href="#" aria-label="LinkedIn Link">
                            <LinkedinIcon/>
                        </FooterSocial>
                        <FooterSocial href="#" aria-label="Instagram Link">
                            <InstaIcon/>
                        </FooterSocial>
                        </BottomFooterRight>
                    </Col>
                </Row>
                </Fade>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;