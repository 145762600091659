import React from 'react';
import {OurProductSection,ProductsHeadingWrapper,
    ProductsHeading,ProductsSubHeading,ProductsInner,ProductsRightImgSecond,ProductsLeftImgFirst,
    ProductsLeftImgSecond,
    ProductsLeft,ProductsRight,ProductsLeftHeading,ProductsLeftPara,ProductsRightImgFirst,
    ProductsBtn,ProductsRowReverse,ProductsRow
} from './OurProduct.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Products  = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saasJson {
                Products {
                    DelayConstant
                    ProductsHeading
                    ProductsSubHeading
                    ProductsRow1{
                        ProductsRightImgFirst
                        ProductsRightImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                    Row{
                        ProductsLeftImgFirst
                        ProductsLeftImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                    ProductsRow2{
                        ProductsRightImgFirst
                        ProductsRightImgSecond
                        ProductsLeftHeading
                        ProductsLeftPara
                        ProductsBtn
                    }
                }
            }
        }
    `);

    return (
        <OurProductSection id="productsContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <ProductsHeadingWrapper>
                            <Fade bottom delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsHeading>{JSONData.saasJson.Products.ProductsHeading}</ProductsHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsSubHeading>{JSONData.saasJson.Products.ProductsSubHeading}</ProductsSubHeading>
                            </Fade>
                        </ProductsHeadingWrapper>
                    </Col>
                </Row>

                <ProductsInner>

                    <ProductsRowReverse padddingBottom={"80px"}>
                        <Col md="7">
                            <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saasJson.Products.ProductsRow1.ProductsRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saasJson.Products.ProductsRow1.ProductsRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>

                        <Col md="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftHeading>
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsLeftHeading}
                                    </ProductsLeftHeading>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftPara>
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsLeftPara}
                                    </ProductsLeftPara>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.ProductsRow1.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>

                    <ProductsRow>
                        <Col md="7">
                        <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                            <ProductsRight style={{textAlign:"left"}}>
                                <ProductsLeftImgFirst src={JSONData.saasJson.Products.Row.ProductsLeftImgFirst} alt=""/>
                                <ProductsLeftImgSecond src={JSONData.saasJson.Products.Row.ProductsLeftImgSecond} alt=""/>
                            </ProductsRight>
                            </Fade>
                        </Col>
                        <Col md="5">
                            <ProductsLeft>
                                <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftHeading>
                                    {JSONData.saasJson.Products.Row.ProductsLeftHeading}
                                    </ProductsLeftHeading>
                                </Fade>
                                <Fade right delay={2*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftPara>
                                    {JSONData.saasJson.Products.Row.ProductsLeftPara}
                                    </ProductsLeftPara>
                                </Fade>
                                <Fade right delay={3*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.Row.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRow>

                    <ProductsRowReverse padddingBottom={"0px"}>
                        <Col md="7">
                            <Fade right delay={1*JSONData.saasJson.Products.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saasJson.Products.ProductsRow2.ProductsRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saasJson.Products.ProductsRow2.ProductsRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>
                        <Col md="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftHeading>
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsLeftHeading}
                                    </ProductsLeftHeading>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsLeftPara>
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsLeftPara}
                                    </ProductsLeftPara>
                                </Fade>
                                <Fade left delay={1*JSONData.saasJson.Products.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saasJson.Products.ProductsRow2.ProductsBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>                
                </ProductsInner>
            </Container>
        </OurProductSection>
    );
}
export default Products;